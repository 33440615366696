import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Carousel from "./carousel"

const Servicio_linea = ({ children, site }) => {
  const query = useStaticQuery(graphql`
    query Serviciolinea {
      allStrapiServicioOnlines (sort: {fields: Orden}) {
        edges {
          node {
            id
            Slug
            Titulo
            Enlace_externo
            Estado
            Orden
            Icono {
              url
            }
          }
        }
      }
    }
  `)

  let url_global = process.env.GATSBY_API_URL
  let projects = query.allStrapiServicioOnlines.edges

  return (
    <>
      <Carousel
        site="personas"
        style={{ margin: 0, display: "block" }}
      ></Carousel>
      <div className="mitexto margen_servicio_linea_texto">
        <div className="w-6/12 mx-auto pb-16 margen_tarjeta_servicio_linea text-center">
          <div className="gap-8 w-max mx-auto container ubicar_dos_columnas_telefonico alineacion_vertical inline-grid grid-cols-3 text-center alineacion_total_servicio_en_linea mitexto">
            {projects.map(({ node: project }, index) => {
              if (project.Estado === "Mostrar") {
                return (
                  <a
                    key={`servici-${index}`}
                    className=""
                    href={"/detalleservicioenlinea/?" + project.Slug}
                  >
                    <div className="mitexto_color text-poppins-bold color-texto-parrafo w-64 margen_servicio_sm">
                      <button className="w-full servicio_linea_tamano_botones font-bold py-2 px-4 rounded-lg inline-flex items-center">
                        <div className="flex flex-col text-center flex items-center ml-auto mr-auto">
                          <img
                            src={url_global + project.Icono.url}
                            className="fill-current w-12 h-12 mr-2"
                            alt={project.Titulo}
                            title={project.Titulo}
                          />
                          <span className="pt-4">{project.Titulo}</span>
                        </div>
                      </button>
                    </div>
                  </a>
                )
              }
            })}
          </div>
        </div>
      </div>
    </>
  )
}

Servicio_linea.propTypes = {
  siteTitle: PropTypes.string,
}

Servicio_linea.defaultProps = {
  siteTitle: ``,
}

export default Servicio_linea
